const keyTypeFindStudies = 'type_find_studies';

const date = new Date();
const year = date.getFullYear();

function getStorage(key, defaultValue) {
    return JSON.parse(localStorage.getItem(key) || defaultValue);
}

export default {
    state: {
        states: getStorage('states', '[]'),
        cities: getStorage('cities', '[]'),
        countries: getStorage('countries', '[]'),
        typeFindStudies: getStorage(keyTypeFindStudies, 'true'),
        branchOffices: getStorage('branchOffices', '[]'),
        accountBranchOffices: getStorage('accountBranchOffices', '[]'),
        subBranchOffices: getStorage('subBranchOffices', '[]'),
        accountSubBranchOffices: getStorage('accountSubBranchOffices', '[]'),
        paymentTypes: getStorage('paymentTypes', '[]'),
        discountCards: getStorage('discountCards', '[]'),
        activeBranchOffice: getStorage('activeBranchOffice', '{}'),
        coins: getStorage('coins', '[]'),
        loggedIn: false,
        years: [
            {value: Number(String(year).substr(3, 1)), text: String(year)},
            {value: Number(String(year - 1).substr(3, 1)), text: String(year - 1)},
            {value: Number(String(year - 2).substr(3, 1)), text: String(year - 2)}
        ],
        year: String(year).substr(3, 1),
        medical: getStorage('medical', '{}'),
        fiscalCfdiUses: getStorage('fiscalCfdiUses', '[]'),
        locations: getStorage('locations', '[]'),
        extraFields: getStorage('extraFields', '[]'),
        foreignWorks: getStorage('foreignWorks', '[]'),
        satRegimenes: getStorage('satRegimenes', '[]'),
        specialPromos: getStorage('specialPromos', '[]'),
        apiurl: getStorage('lapi_api_url', '{}', true),
    },
    mutations: {
        setMainData(state, val) {
            for (let value in state) {
                if (state.hasOwnProperty(value)  && val[value]) {
                    state[value] = val[value];
                    localStorage.setItem(value, JSON.stringify(state[value]));
                }
            }
        },
        setFindTypeStudies(state, value) {
            state.typeFindStudies = Boolean(value);
            localStorage.setItem(keyTypeFindStudies, value);
        },
        setDefaultYear(state, value) {
            state.year = String(value);
        },
        setLoggedIn(state, value) {
            state.loggedIn = value;
        },
        setForeignWorks(state, value) {
            state.foreignWorks = value;
        },
        setSatRegimenes(state, value) {
            state.satRegimenes = value;
            localStorage.setItem('satRegimenes', JSON.stringify(value));
        },
        setSpecialOffers(state, value) {
            state.specialOffers = value;
            localStorage.setItem('specialPromos', JSON.stringify(value));
        },
        setApiurl(state, value) {
            state.apiurl = value;
            localStorage.setItem('lapi_api_url', JSON.stringify(value));
        },
        setServers(state, value) {
            state.servers = value;
        }
    },
    actions: {
        setDefaultYear(context, value) {
            context.commit('setDefaultYear', value);
        },
        setForeignWorks(context, value) {
            context.commit('setForeignWorks', value);
        },
        setApiurl(context, value) {
            context.commit('setApiurl', value);
        },
        setServers(context, value) {
            context.commit('setServers', value);
        }
    },
    getters: {
        authPriceList(state) {
            return state.activeBranchOffice.price_list || 'M22';
        },
        months() {
            return [
                {code: '01', name:'Enero'},
                {code: '02', name:'Febrero'},
                {code: '03', name:'Marzo'},
                {code: '04', name:'Abril'},
                {code: '05', name:'Mayo'},
                {code: '06', name:'Junio'},
                {code: '07', name:'Julio'},
                {code: '08', name:'Agosto'},
                {code: '09', name:'Septiembre'},
                {code: '10', name:'Octubre'},
                {code: '11', name:'Noviembre'},
                {code: '12', name:'Diciembre'},
            ]
        },
        servers(state) {
            const result = [];
            if(window.location.toString().startsWith('http://recepcion.lapi.site')){
                result.push({
                    code: 'local',
                    url: 'http://api-gateway.lapi.site/v1'
                })
            }
            if(window.location.toString().startsWith('https://alfalab.lapi.mx') || window.location.toString().startsWith('https://beta.recepcion.lapi.mx') ){
                result.push({
                    code: 'gateway2',
                    url: 'https://gateway2.lapi.mx/v1'
                })
                result.push({
                    code: 'gateway2alt',
                    url: 'https://gateway2alt.lapi.mx/v1'
                })
                result.push({
                    code: 'gateway',
                    url: 'https://gateway.lapi.mx/v1'
                })
            }
            if(window.location.toString().startsWith('https://testing.recepcion.lapi.mx')){
                result.push({
                    code: 'testing',
                    url: 'https://api.lapi.mx/v1'
                })
                result.push({
                    code: 'alpha',
                    url: 'https://alpha.lapi.mx/v1'
                })
            }
            return result;
        }
    }
}
